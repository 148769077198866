.Wrapper {
  display: flex;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  font-family: "Segoe UI", sans-serif;
  overflow: scroll;
}

.ProductDetail {
  position: absolute;
  display: flex;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

.ImageWrapper {
  position: relative;
  min-width: 330px;
}

.Image {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 10%;
  left: 5%;
  max-width: 320px;
}
.Image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.Context {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}

.Context h3,
p {
  color: #555;
}

.Context h3,
p,
.Type {
  margin: 10px 0 30px;
  /*padding-right: 30px;*/
}

.Context h3 {
  text-transform: capitalize;
  font-weight: normal;
  font-size: 22px;
}

.Context p {
  font-size: 16px;
  line-height: 25px;
}

.Context h5 {
  font-weight: lighter;
  font-size: 16px;
}

.Label {
  margin: 0;
  font-size: 23px;
  color: rgb(100, 100, 100);
  font-weight: normal;
}

.TypeWrapper {
  display: flex;
  flex-wrap: wrap;
}

.Type {
  text-align: center;
  width: 80px;
  padding: 10px;
  color: white;
  margin-right: 20px;
}

.CloseIcon {
  position: absolute;
  right: 25px;
  top: 20px;
  font-size: 25px;
  color: rgb(119, 119, 119);
  z-index: 5;
}

@media (max-width: 991px) {
  .ProductDetail {
    flex-direction: column;
  }
  .ImageWrapper {
    max-width: 250px;
    margin: 20px auto;
    height: 500px;
  }
  .Image {
    width: 100%;
  }
  .Context h3 {
    font-size: 20px;
  }
  .Context p {
    font-size: 13px;
    line-height: 20px;
  }
  .Context h5 {
    font-size: 13px;
  }
  .Label {
    font-size: 18px;
  }
  .Type {
    width: 50px;
    font-size: 13px;
  }
  .Context h3,
  p,
  .Type {
    margin: 10px 20px 20px 0;
  }
}

@media (max-width: 600px) {
  .ImageWrapper {
    width: 100%;
    min-width: 0;
    height: 300px;
  }
  .Image {
    position: relative;
    left: 0;
    width: 100%;
  }
}
