.MenuIcon {
  position: absolute;
  width: 25px;
  height: 24px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  z-index: 500;
  cursor: pointer;
}

.MenuIcon div{
  position: relative;
  transition: all 0.s linear;
    width: 90%;
    height: 3px;
    background-color: white;
    border-radius: 10%;
}

.MenuIconWrapper{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1555;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  background-color: #00000085;
  border-radius: 50%;
}

@media (min-width: 992px){
    .MenuIconWrapper{
        display: none;
    }
}