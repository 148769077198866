.Modal {
  position: fixed;
  z-index: 1200;
  background-color: white;
  width: 70%;
  left: 15%;
  top: 10%;
  height: 80vh;
  opacity: 0; 
}
@media (max-width: 991px) {
  .Modal {
    position: absolute;
    width: 76%;
    height: 84vh;
    top: 8%;
    left: 13%;
  }
}
@media (max-width: 600px) {
  .Modal {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
  }
}
