.Nav {
    text-align: center;
    display: flex;
    justify-content: center;
    height: 80px;
    background-color: #00000078;
    position: relative;
    width: 90%;
    float: left;
  }

  .Nav > li {
    height: 100%;
    line-height: 80px;
    padding: 0 10px;
    transition: all 0.2s linear;
    display: block;
  }
  .Nav > li a {
    font-weight: 300;
    font-size: 20px;
    display: block;
    color: white;
    text-decoration: none;
  }
  .Disabled{
    pointer-events: none;
  }

  @media ( max-width:991px){
    .Nav{
      display: none;
    }
  }
