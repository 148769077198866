.Nav {
  position: absolute;
  top: 0;
  right: 0;
  padding: 90px 0 0;
  z-index: 30;
  max-width: 270px;
  width: 270px;
  height: 100vh;
  z-index: 1200;
  background-color: #00000090;
}
.Nav li {
  line-height: 40px;
}
.Nav li a {
  display: block;
  font-size: 23px;
  padding-left: 30px;
  color: rgba(255, 255, 255, 0.85);
}

.Disabled{
  pointer-events: none;
}