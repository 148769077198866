.ProductPage {
  height: 100vh;
  width: 100%;
}

.ImageLayer {
  position: relative;
  width: 300px;
  height: 300px;
  background-color: transparent;
  margin-bottom: 20px;
  border-radius: 100%;
  margin: auto;
  cursor: pointer;
}

.ImageLayer img {
  position: absolute;
  height: 240px;
  object-fit: contain;
  left: 10%;
  z-index: 50;
  top: calc(50% - 120px);
  max-width: 240px;
}
.ImageLayer .TransparentDiv,
.ImageLayer .StaticDiv {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 100%;
}

.ImageLayer .StaticDiv {
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background-color: rgb(235, 229, 229);
  z-index: 5;
  top: 0;
  right: 0;
}
.ImageLayer .TransparentDiv {
  top: 0;
  left: 0;
  clip-path: polygon(101% 0, 0% 101%, 0 0);
  z-index: -1;
  background-color: rgb(235, 229, 229, 0.9);
}

.ImageLayer .TransparentDivHovered {
  background-color: rgba(235, 229, 229, 0.507);
  left: -10px;
}

.ImageLayer .StaticDivHovered {
  right: -10px;
}
.ProductName {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
}

@media (max-width: 1350px) {
  .ImageLayer {
    width: 250px;
    height: 250px;
  }
  .ImageLayer .TransparentDiv,
  .ImageLayer .StaticDiv {
    width: 250px;
    height: 250px;
  }
  .ImageLayer img {
    height: 200px;
    left: 5px;
    top: calc(50% - 100px);
  }
}

@media (max-width: 767px) {
  .ImageLayer {
    width: 210px;
    height: 210px;
  }
  .ImageLayer .TransparentDiv,
  .ImageLayer .StaticDiv {
    width: 210px;
    height: 210px;
  }
  .ImageLayer img {
    height: 170px;
    left: -4%;
    top: calc(50% - 85px);
  }
}
