.Home {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  background-position: 0%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100%;
}
.Scroll {
  position: fixed;
  width: 100%;
  height: 100%;
}

.Scroll img {
  position: fixed;
  width: 120px;
  height: 120px;
  right: calc(50% - 75px);
  bottom: 20px;
  z-index: 999999;
}

.HomeSection {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.HomeSection .BackImageSide {
  position: absolute;
  width: 100%;
  z-index: 30;
  background-position: 0%;
  background-size: auto 100%;

  /* background-size: auto 120%; */
  background-repeat: no-repeat;
  height: 100vh;
}

.Content {
  position: absolute;
  width: 450px;
  height: 100vh;
  right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Content h3 {
  color: white;
  font-size: 40px;
  margin: 0;
  font-weight: lighter;
  text-transform: uppercase;
}
.Content p {
  color: white;
  white-space: normal;
  font-weight: lighter;
  line-height: 30px;
  font-size: 22px;
}

.Content a {
  text-decoration: underline;
  color: #fbb300;
  font-size: 22px;
}

.HomeSection .BackColorSide {
  position: absolute;
  top: 0;
  z-index: 20;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Image {
  position: absolute;
  top: 0;
  background-attachment: fixed;
  left: 0;
  width: 100%;
  z-index: 30;
  height: 100%;
  background-size: auto 80%;
  background-position: 50%;
  background-repeat: no-repeat;
}

video {
  /*height: 100%;*/
  width: 100%;
  /*float: left;*/
  top: 0;
  left: 0;
  /*margin: 0 auto;*/
  position: fixed;
  background-size: cover;
  z-index: -999999;
}

@media (max-width: 1550px) {
  .HomeSection .BackImageSide {
    background-size: auto 100%;
  }
  .Image {
    background-size: auto 70%;
  }
  .Content {
    width: 350px;
  }
  .Content h3 {
    font-size: 25px;
  }
  .Content p {
    font-size: 16px;
  }
  .Content a {
    font-size: 20px;
  }
}
@media (max-width: 1025px) {
  .HomeSection .BackImageSide {
    background-size: auto 100%;
  }
  .Image {
    background-size: auto 55%;
  }
  video {
    width: unset;
    height: 100%;
  }
}
@media (max-width: 991px) {
  .HomeSection .Content {
    width: 250px;
  }
  .HomeSection .Content p {
    font-size: 16px;
  }
  .HomeSection .Content h3 {
    font-size: 25px;
  }
  .HomeSection .Content a {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .Scroll img {
    width: 90px;
    height: 90px;
    right: calc(50% - 45px);
  }
  .HomeSection .BackImageSide {
    left: -20%;
  }
  .HomeSection .Image {
    background-position-y: 118%;
  }

  .HomeSection .Content {
    text-align: center;
    width: 100%;
    top: -100px;
  }
  .HomeSection .Content h3 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 18%;
  }
  .HomeSection .Content p {
    font-size: 13px;
    max-width: 90%;
    margin: auto;
    margin-left: 30%;
    line-height: 20px;
  }
  .HomeSection .Content a {
    font-size: 15px;
  }
}

@media (max-width: 450px) {
  .Home {
    background-attachment:scroll;
    background-image: url("../../../../datas/Home/Images/main-mobile.jpeg");
  }
  .HomeSection .Content {
    width: 98%;
  }
  .HomeSection .BackImageSide {
    background-size: 94% 56%;
    bottom: -30%;
    transform: rotate(-90deg);
    left: 0;
  }
  .HomeSection .Image {
    background-position-y: 90%;
    background-position-x: 45%;
  }
  .Image {
    background-size: auto 50%;
    background-attachment:scroll;
  }
  .HomeSection .Content h3 {
    margin-top: -50%;
  }
  .HomeSection .Content p {
    font-size: 13px;
    max-width: 90%;
    margin: 10px auto;
  }
  .Content p {
    max-height: 13%;
    margin-left: unset;
  }
  #background-video {
    height: 100%;
    width: unset;
    position: fixed;
    background-size: cover;
    z-index: -999999;
  }
  video {
    display: none;
  }
}
