.BackImageSide {
    position: fixed;
    background-image: url("../../../datas/Home/Images/about-bg.jpg");
    width: 100%;
    background-size: cover;
    background-position-y: 10%;
    height: 100vh;
    top: 0;
}
.Contact {
    width: 100%;
    height: 100%;
    /*background-image: url("../../../datas/Home/Images/about-bg.jpg");*/
    background-size: cover;
    background-position-y: 10%;
    position: absolute;
    top: 0;
}
.ContactWrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90%;
    left: 5%;
    overflow: hidden;
}
.Contact h3 {
    position: absolute;
    text-align: center;
    margin: 0;
    top: 0;
    left: 0;
    font-weight: 400;
    font-size: 30px;
    color: white;
    width: 50%;
    line-height: 50vh;
}
.ContactDetails {
    background-color: #00000078;
    position: absolute;
    width: 45%;
    left: 0;
    /*top: 10%;*/
    display: flex;
    justify-content: center;
    min-height: 40px;
    align-items: center;
    /*height: 50vh;*/
    padding: 0 5px;
    color: white;
}
.Map {
    position: absolute;
    width: 100%;
    left: 55%;
    top: 30%;
}

.Map video {
    width: 50%;
    height: 100%;
    z-index: 0;
    left: 50%;
}
.Image {
    width: auto;
    max-height: 450px;
}
.Image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.ContentDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: justify;
}
.Content {
    color: white;
    position: relative;
    display: flex;
    line-height: 20px;
}
.key {
    width: 70px;
    font-weight: bold;
    padding-right: 15px;
}
.value {
    font-weight: lighter;
    line-height: 25px;
    font-style: italic;
}
.BackImg {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 400px;
    height: 400px;
    background-position-x: -20%;
    background-size: cover;
    background-position-y: 120px;
    background-repeat: no-repeat;
}
.Media {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 10px;
    height: 200px;
}
.Icon {
    width: 35px;
    height: 35px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
}
.Icon span {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #38a9dc;
}
.Icon span i {
    line-height: 35px;
    font-size: 20px;
}

@media (max-width: 768px) {
    .Contact h3{
        display: none;
    }
    .BackImg {
        width: 300px;
    }
    .ContactDetails {
        flex-direction: column;
        width: 100%;
        top: 20%;
        color: white;
    }
    .Contact .Media {
        flex-direction: initial;
        justify-content: space-evenly;
        margin: 20px 0;
        height: auto;
        width: 100%;
    }
    .Contact .ContentDetails {
        width: 100%;
        color: white;
        overflow: scroll;
        font-size: 10px;
    }
    .Map{
        left: 28%;
        top: 40%;
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }
    .Map iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 45%;
        height: auto;
    }
    .Image {
        display: flex;
        width: auto;
        max-height: 215px;
    }
    .Video {
        left: 5%;
        top: 40%;
    }
}

@media (max-width: 450px){
    .Map{
        left: 0;
        top: 20%;
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }
    .Map iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .Image {
        width: auto;
        max-height: 250px;

    }
    #about-video {
        margin-top: 10px;
        width: 94%;
        left: 3%;
    }
    .Contact h3{
        display: none;
    }
    .ContactDetails {
        font-size: 11px;
    }
}
