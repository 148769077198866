.InnerNavigation{
    background-color: #00000078;
    padding: 0;
}

.InnerNavigation li{
    line-height: 30px;
    padding: 0 10px;
}
.InnerNavigation li a {
    font-size: 18px;
}
