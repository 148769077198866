.Contact {
  width: 100%;
  height: 100%;
  background-image: url("../../../datas/Home/Images/contacts-bg.jpg");
  background-size: cover;
  background-position-y: 60%;
}
.ContactWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.Contact h3 {
  /*position: absolute;*/
  text-align: center;
  margin: 0;
  /*top: 0;*/
  /*left: 0;*/
  /*font-weight: 400;*/
  font-size: 30px;
  color: white;
  /*width: 50%;*/
  /*line-height: 50vh;*/
}
.ContactDetails {
  background-color: #00000078;
  position: absolute;
  width: 50%;
  left: 0;
  top: 25%;
  display: flex;
  justify-content: center;
  min-height: 170px;
  align-items: center;
  height: 50vh;
}
.Image {
  width: 25%;
}
.Image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.Map {
  position: absolute;
  /*top: 50%;*/
  right: 0;
  width: 50%;
  /*height: calc(100% - 80px);*/
}
.ContentDetails {
  height: 200px;
  width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Content {
  color: white;
  position: relative;
  display: flex;
  line-height: 20px;
}
.key {
  width: 70px;
  font-weight: bold;
  padding-right: 15px;
}
.value {
  font-weight: lighter;
  line-height: 25px;
  font-style: italic;
}
.BackImg {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 400px;
  height: 400px;
  background-position-x: -20%;
  background-size: cover;
  background-position-y: 120px;
  background-repeat: no-repeat;
}
.Media {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 10px;
  height: 200px;
}
.Icon {
  width: 35px;
  height: 35px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
}
.Icon span {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #38a9dc;
}
.Icon span i {
  line-height: 35px;
  font-size: 20px;
}

.owlNext, .owlPrev{
  z-index: 1;
  position: absolute;
  top: calc(50% - 20px);
  font-size: 40px;
  background-color: transparent;
  border: none;
  outline: none;
  color: rgb(252, 241, 241);
  cursor: pointer;
}
.owlPrev{

  left: 10px;
}
.owlNext{
  right: 10px;
}

.OwlCustom {
  display: flex !important;
  top: 10%;
}
.TradeNetwork {
  background-color: #00000078;
  display: grid;
  position: relative;
  justify-content: center;
  padding-top: 10px;
}
.TradeNetwork span {
  color: white;
  font-size: 1.5em;
  padding-top: 10px;
  padding-bottom: -20px;
  text-align: center;
}
.TradeNetwork img {
  max-width: 200px;
}
@media (max-width: 768px){
  .Map{
    height: 50vh;
    width: 100%;
    top: 55%;
  }
  .Contact h3{
    display: block;
  }
  .ContactDetails{
    height: 50vh;
    width: 100%;
    top: 0;
  }
}

@media (max-width: 500px) {
  .BackImg {
    width: 300px;
    height: 300px;
  }
  .ContactDetails {
    flex-direction: column;
  }
  .Contact .Media {
    flex-direction: initial;
    justify-content: space-evenly;
    margin: 20px 0;
    height: auto;
    width: 100%;
  }
  .Contact .ContentDetails {
    width: 85%;
    margin: 0 auto;
  }
}

@media (max-width: 400px){
  .Map{
    height: 40vh;
    width: 100%;
    top: 65%;
  }
  .Contact h3{
    font-size: 15px;
  }
  .ContactDetails{
    height: 60vh;
    width: 100%;
    top: 0;
    justify-content: flex-end;
  }
  .TradeNetwork img {
    max-height: 100px;
    width: auto;
  }
}
