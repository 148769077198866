.Logo {
  position: fixed;
  left: 10px;
  z-index: 25;
  width: 200px;
   height: 80px;
  float: left;
}
.Logo img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1500px){
  .Logo{
    width: 140px;
  }
}

@media (max-width: 991px){
  .Logo{
    width: 100px;
  }
}
