.languageSelector {
    text-align: center;
    justify-content: center;
    display: flex;
    background-color: #00000078;
    height: inherit;
}

button {
    color: white !important;
}


@media ( max-width:991px ){
    .languageSelector {
        background-color: transparent;
        text-align: center;
        justify-content: center;
        height: 30px;
    }
}
