body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.form-control:focus {
  border-color: transparent !important;
}

textarea {
  resize: none;
}

textarea:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

span {
  display: inline-block;
}

ul {
  margin: 0;
}

ul li {
  list-style-type: none;
}

textarea {
  resize: none;
}

.padding-50 {
  padding: 50px 0;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: black;
}
::-webkit-scrollbar {
  /*width: 7px;*/
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(49, 48, 48);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00000078;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  background: #00000078;
}
