.owl-carousel {
    top: 5% !important;
}
.owlNext, .owlPrev{
    position: absolute;
    top: calc(50% - 40px);
    font-size: 40px;
    background-color: transparent;
    border: none;
    outline: none;
    color: rgb(252, 241, 241);
    cursor: pointer;
}
.owlPrev{

    left: 10px;
}
.owlNext{
    right: 10px;
}

.OwlCustom{
    display: flex !important;
    justify-content: center;
    top: 6% !important;
}
